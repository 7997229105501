import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../layouts/Layout';
import Home from '../pages/Home';
import { About } from '../pages/About';
import { Products } from '../pages/Products';
import { Services } from '../pages/Services';

const AppRoutes = () => {
  return (
    <Layout>
    <Routes>
     <Route path="/" element={<Home />} />
       <Route path="/about" element={<About />} />
     <Route path="/products" element={<Products />} />
       <Route path="/services" element={<Services />} />  
       {/* <Route path="*" element={<services />} />   */}
    </Routes>
    </Layout>
  );
};

export default AppRoutes;
