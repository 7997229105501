import {  NavLink } from 'react-router-dom';
import logo from '../assets/images/logo-brosislam.png';
import useCurrentRoute from '../routes/CurrentRoute';
export const Navbar = () => {
    const baseClass = "block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";
    const activeClass = "text-primary";
    const currentRoute = useCurrentRoute();
    return (
        <nav className={`bg-${currentRoute === "/" ? 'transparent fixed' : 'white'}  top-0 left-0 w-full border-gray-200 dark:bg-gray-900 z-50`} >
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="https://flowbite.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-20" alt="Flowbite Logo" />
                </a>
                <button
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-default"
                    aria-expanded="false"
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                <ul className="px-10 py-5 font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => `${baseClass} ${isActive ? activeClass : 'text-gray-900'}`}
                aria-current="page"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => `${baseClass} ${isActive ? activeClass : 'text-gray-900'}`}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                className={({ isActive }) => `${baseClass} ${isActive ? activeClass : 'text-gray-900'}`}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products"
                className={({ isActive }) => `${baseClass} ${isActive ? activeClass : 'text-gray-900'}`}
              >
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) => `${baseClass} ${isActive ? activeClass : 'text-gray-900'}`}
              >
                Contact
              </NavLink>
            </li>
          </ul>
                </div>
            </div>
        </nav>


    );
}