import { StarIcon } from "@heroicons/react/16/solid";
import tableImage from '../assets/images/img2.jpg';

export const About = () => {
    const starIcons = () => {
        let stars = [];
        for (let index = 0; index < 5; index++) {
            stars.push(<StarIcon key={index} className='h-10 w-10 text-yellow-300' />);
        }
        return stars;
    };
    return (<>
        <section>
            <h2 className='text-4xl font-bold text-center my-10'>
                About <span className='text-primary'>Brosislam</span>
            </h2>
            <div className='container mx-auto px-4'>
                <div className='grid grid-cols-2 gap-0 h-auto'>
                    <div className='text-center'>
                        <img src={tableImage} className='inline-block max-w-full h-96 rounded-xl' alt="Table" />
                    </div>
                    <div>
                        <p className='my-5'>
                            Founded with a vision to redefine surfaces, Brosislam has grown into a leading provider of premium laminates. Our commitment to excellence and customer satisfaction drives us to continually innovate and expand our product offerings. We pride ourselves on delivering not only superior products but also exceptional service.
                        </p>
                        <div className='flex flex-row my-5'>
                            {starIcons()}
                        </div>
                        <p className='my-5'>5 Star Rated Company In <span className='font-bold'>Ahmedabad</span></p>
                        <p>Browse through our portfolio to see how Brosislam laminates have transformed various spaces. From residential interiors to commercial establishments, our laminates add a touch of elegance and functionality.</p>
                    </div>
                </div>
            </div>
        </section>
    </>);
};