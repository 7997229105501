import productImage from '../assets/images/specialization.jpg';
import airMikaImage from '../assets/pdfs/airmika.png';
import airLeafImage from '../assets/pdfs/airleaf.png';
import masterpieImage from '../assets/pdfs/masterpie.png';
import { MyModal } from '../components/Modal';

export const Products = () => {
    return (<>
        <section className='container mx-auto px-4'>
            <h2 className='text-4xl font-bold text-center my-5'>Products In <span className='text-primary'>Brosislam</span></h2>
            <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-1 my-10 '>
                <div className='py-5'>
                    <h3 className='text-3xl font-bold my-5'>INTERIOR <span className='text-primary'>FABRICS</span></h3>
                    <p>
                        Add a touch of elegance to your residential and commercial furniture whilst enhancing its durability and visual appeal. Our 0.92 mm interior laminate makes for an ideal choice. Manufactured using state-of-the-art technology, our interior laminate is an upper-crest option to completely transform your furniture’s look. Our 0.92 mm laminate interior fabrics are pre-finished which means you do not have to worry about finishing details at all. Brosis offers 0.92 mm interior fabrics in a whole gamut of enticing designs that perfectly match the theme of your property.
                    </p>
                    <ul class="list-disc pl-5 mt-3">
                        <li>Best For Inner Laminates</li>
                        <li>Highly durable</li>
                        <li>Natural appeal</li>
                        <li>Water-resistance</li>
                        <li>Low maintenance</li>
                        <li>Weather-resistance</li>
                        <li>Used in modular cabinets</li>
                        <li>Also used on wall shelves in bathrooms, bookshelves, and kitchen</li>
                    </ul>
                      
                
                </div>
                <div className='text-center'> <img src={productImage} className=' text-center max-w-full inline-block object-cover rounded-xl' alt='Brosislam' /></div>
            </div>



            <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-1 my-10'>
                <div className='text-center'> <img src={airMikaImage} className=' text-center max-w-full inline-block object-cover rounded-xl' alt='Brosislam' /></div>
                <div className='py-5'>
                    <h3 className='text-3xl font-bold my-5'>AIR<span className='text-primary'>MIKA</span></h3>
                    <p>
                        AirMika Yet another mile stone from the House of Brosis Laminates, A Leading Brand Name in Premium grade and SuperiorQuality ofDecorativeLaminatesrangethatcan onlybelimited by the imagination.
                        At par with latest Product Design, Technology and Techniques our focus remains on consistent quality and passion to bring world class product.
                        AirMika offers Laminates with excellent design which create charm in your lifestyle. Believing that interior design has become fundamental part of our lives AirMika has the most beautiful range of shades and designs to offer for your homes, commercial and institutional spaces.
                    </p>
                    <ul class="list-disc pl-5 mt-3">
                        <li>Best For Inner Laminates</li>
                        <li>Highly durable</li>
                        <li>Natural appeal</li>
                        <li>Water-resistance</li>
                        <li>Low maintenance</li>
                        <li>Weather-resistance</li>
                        <li>Used in modular cabinets</li>
                        <li>Also used on wall shelves in bathrooms, bookshelves, and kitchen</li>
                    </ul>
                    <MyModal pdfLink='/pdfs/AIRMIKA.pdf' pdfName={'AIRMIKA'} />

                </div>

            </div>







            <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-1 my-10'>
                <div className='py-5'>
                    <h3 className='text-3xl font-bold my-5'>AIR <span className='text-primary'>LEAF</span></h3>
                    <p>
                        Airleaf is a leading manufacturer of superior quality of Premium Plain Colour laminates range that can only be limited by the imagination. It maintained consistent growth rate over the year.
                        Airleaf offers simplicity with carefully chosen laminates
                        which
                        creates charm in your life style.
                        Airleaf believe that Interior space design have become fundamental part of our lives. we have the most beautiful Plain laminates for your home and space.
                    </p>
                    <MyModal pdfLink='/pdfs/Airleaf.pdf' pdfName={'Airleaf'} />


                </div>
                <div className='text-center'> <img src={airLeafImage} className=' text-center max-w-full inline-block object-cover rounded-xl' alt='Brosislam' /></div>
            </div>



            <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-1 my-10'>
                <div className='text-center'> <img src={masterpieImage} className=' text-center max-w-full inline-block object-cover rounded-xl' alt='Brosislam' /></div>
                <div className='py-5'>
                    <h3 className='text-3xl font-bold my-5'>MASTERPIE BY <span className='text-primary'>INTER-EO</span></h3>
                    <p>
                        Created to give your spaces fabulous decors, INTER-EO A fabric series spread a realm of exuberance and excellence with its vivacious form & function.
                        Give your environment a dash of class with these stylish designs that splash an exemplary statement with intricate detail and unparalleled finesse.
                        Welcome to the world of luxuriance and lavish ecstasy!
                    </p>
                    <ul class="list-disc pl-5 mt-3">
                        <li>Best For Inner Laminates</li>
                        <li>Highly durable</li>
                        <li>Natural appeal</li>
                        <li>Water-resistance</li>
                        <li>Low maintenance</li>
                        <li>Weather-resistance</li>
                        <li>Used in modular cabinets</li>
                        <li>Also used on wall shelves in bathrooms, bookshelves, and kitchen</li>
                    </ul>
                    <MyModal pdfLink='/pdfs/MASTERPIECE BY INTER-EO.pdf' pdfName={'INTER'} />

                </div>

            </div>
        </section>
    </>);
}