import React from "react";
import {
  CheckCircleIcon,
  ShieldCheckIcon,
  SunIcon,
  FireIcon,
  ExclamationCircleIcon,
  BugAntIcon,
  WrenchScrewdriverIcon,
  ArrowLeftCircleIcon,
  ClipboardDocumentListIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";

const features = [
  {
    name: "Scratch Resistant",
    description: "Protects surfaces from scratches and wear.",
    icon: CheckCircleIcon,
  },
  {
    name: "Stain Resistant",
    description: "Resists staining from liquids and other substances.",
    icon: ShieldCheckIcon,
  },
  {
    name: "All Weather Resistant",
    description: "Performs well under various weather conditions.",
    icon: SunIcon,
  },
  {
    name: "Fire Retardant",
    description: "Reduces the risk of fire spreading.",
    icon: FireIcon,
  },
  {
    name: "Abrasion Resistant",
    description: "Withstands surface wear and rubbing.",
    icon: ExclamationCircleIcon,
  },
  {
    name: "Anti Fungal & Anti Bacterial",
    description: "Inhibits the growth of fungi and bacteria.",
    icon: BugAntIcon,
  },
  {
    name: "Carpenter Friendly",
    description: "Easy to work with using standard tools.",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Wide Ranges",
    description: "Available in a variety of styles and sizes.",
    icon: ArrowLeftCircleIcon,
  },
  {
    name: "Low Maintenance",
    description: "Requires minimal upkeep to maintain quality.",
    icon: ClipboardDocumentListIcon,
  },
  {
    name: "Premium Appearance",
    description: "Offers a high-end, aesthetically pleasing look.",
    icon: SparklesIcon,
  },
];

const ProductFeatures = () => {
  return (
    <div className="max-w-5xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
       <h2 className='text-4xl font-bold text-center my-5'>Product Features <span className='text-primary'>Brosislam</span></h2>
      <ul className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {features.map((feature) => (
          <li
            key={feature.name}
            className="col-span-1 flex flex-col items-center space-y-2 rounded-lg bg-white shadow-md p-4"
          >
            <feature.icon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <div className="text-center">
              <h3 className="text-lg font-medium text-gray-900">
                {feature.name}
              </h3>
              <p className="text-sm text-gray-500">{feature.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductFeatures;
