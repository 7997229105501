import { StarIcon } from '@heroicons/react/16/solid';
import homeImage from '../assets/images/img1.jpg';
import tableImage from '../assets/images/img2.jpg';
import { Services } from './Services';
import { Products } from './Products';
import ProductFeatures from './ProductFeatures';
function Home() {

    const starIcons = () => {
        let stars = [];
        for (let index = 0; index < 5; index++) {
            stars.push(<StarIcon key={index} className='h-10 w-10 text-yellow-300' />);
        }
        return stars;
    };
    return (
        <>
            <section className="grid md:grid-cols-2 sm:grid-cols-1 gap-4" >
                <div className="relative w-screen h-screen">
                    <img
                        src={homeImage}
                        className="absolute inset-0 md:w-1/2 sm:w-full h-full object-cover"
                        alt="Home"
                    />
                </div>
                <div className='h-screen flex flex-col items-start justify-center p-4'>
                    <h1 className='font-bold text-6xl mb-4'>
                        Welcome To <br />
                        <span className='text-primary'>Brosislam</span>
                    </h1>
                    <p className='text-lg max-w-xl'>
                        Discover the finest quality laminates for your home and office spaces. At Brosislam, we blend innovation with aesthetics to provide you with an extensive range of laminates that meet the highest standards of durability and design.
                    </p>
                </div>
            </section>
            <section>
                <h1 className='text-4xl font-bold text-center my-10'>
                    About <span className='text-primary'>Brosislam</span>
                </h1>
                <div className='container mx-auto px-4'>
                    <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-0 h-auto'>
                        <div className='text-center'>
                            <img src={tableImage} className='inline-block max-w-full h-96 rounded-xl' alt="Table" />
                        </div>
                        <div>
                            <p className='my-5'>
                                Founded with a vision to redefine surfaces, Brosislam has grown into a leading provider of premium laminates. Our commitment to excellence and customer satisfaction drives us to continually innovate and expand our product offerings. We pride ourselves on delivering not only superior products but also exceptional service.
                            </p>
                            <div className='flex flex-row my-5'>
                                {starIcons()}
                            </div>
                            <p className='my-5'>5 Star Rated Company In <span className='font-bold'>Ahmedabad</span></p>
                            <p>Browse through our portfolio to see how Brosislam laminates have transformed various spaces. From residential interiors to commercial establishments, our laminates add a touch of elegance and functionality.</p>
                        </div>
                    </div>
                </div>
            </section>


            <Products />

            <ProductFeatures />

            <Services />

            <section className='my-20 container mx-auto'>
                <h1 className='text-4xl font-bold text-center '>Testimonial In <span className='text-primary'>Brosislam</span></h1>
                <h5 className='font-semibold text-center text-base'>What Our Clients Say</h5>
                <div className='mt-10 bg-primary rounded-xl p-10 text-white'>
                    <p className='text-2xl italic text-center'>“Brosislam provided exceptional service and high-quality laminates for our office renovation. The results are stunning!” </p>
                    <h6 className='text-end'>– Jane D.</h6>

                </div>
            </section>


           

        </>
    );
}
export default Home;