import { ClipboardDocumentIcon, DocumentTextIcon, StarIcon } from "@heroicons/react/16/solid";
import { Card } from "../components/Card";

export const Services = () => {
    return (<>
        <section className='my-20 container mx-auto'>
            <h2 className='text-4xl font-bold text-center '>Services In <span className='text-primary'>Brosislam</span></h2>
            <h5 className='font-semibold text-center text-base'>At Brosislam, we offer more than just products. Our services include</h5>
            <div className='grid md:grid-cols-3 md:text-start sm:grid-cols-1 sm:text-center gap-2 mt-10'>

                <Card Icon={DocumentTextIcon} title='Consultation' description='Personalized guidance to help you choose the perfect laminate for your project.' />
                <Card Icon={ClipboardDocumentIcon} title='Customization' description='Tailored laminate designs to meet specific needs and preferences.' />
                <Card Icon={StarIcon} title='Installation Support' description='Professional advice and assistance to ensure seamless installation.' />

            </div>
        </section>
    </>);
}