import React, { useState } from 'react';
import axios from 'axios';

export const  MyModal = ({pdfLink,pdfName}) => {
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        area_city: '',
        category: '',
      });
    //   const [status, setStatus] = useState('');
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value });
          console.log(formData);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        axios.post('https://brosislam.technopeakinnovations.com/phpmail/mail.php', formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
          })
          .then((response) => {
            if (response.data.type === 'success') {
            //   setStatus('Success! ' + response.data.message);
            document.getElementById('closeModalButton'+pdfName).click();
              openPdf();
            } else {
            //   setStatus('Error! ' + response.data.message);
            }
          })
          .catch((error) => {
            // setStatus('Error! Could not send the email.');
          });
      };
    
    
    
    const openPdf = () => {
        window.open(pdfLink, '_blank');
      };
return (
<>
<button data-modal-target={'crud-modal'+pdfName} data-modal-toggle={'crud-modal'+pdfName} class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
  View PDF
</button>
<div id={'crud-modal'+pdfName} tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
        
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

                
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Fill the form to view the PDF
                </h3>
                <button type="button" id={'closeModalButton'+pdfName} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle={'crud-modal'+pdfName}>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <form class="p-4 md:p-5" onSubmit={handleSubmit}>
                <div class="grid gap-4 mb-4 grid-cols-2">
                    <div class="col-span-2 sm:col-span-1">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                        <input type="text" name="name" id="name" onChange={handleChange} required={true} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter your name" />
                    </div>
                    <div class="col-span-2 sm:col-span-1">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone No</label>
                        <input type="number" name="phone_number" id="phone_number" required onChange={handleChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter your Phone No." />
                    </div>
                    <div class="col-span-2 sm:col-span-1">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Area/City</label>
                        <input type="text" name="area_city" id="area_city" required onChange={handleChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter your Area/City" />
                    </div>
                    
                    <div class="col-span-2 sm:col-span-1">
                        <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                        <select id="category" name='category' onChange={handleChange} required class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                            <option selected="">Select category</option>
                            <option value="Distributor">Distributor</option>
                            <option value="Dealer">Dealer</option>
                            <option value="Architect/Interior">Architect/Interior</option>
                            <option value="Designer">Designer</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>

                </div>
                <button type="submit" class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    View PDF
                </button>
            </form>
        </div>
    </div>
</div>
</>);
}