import React from 'react';

const Footer = () => {
    return (
        <>
            <section className='my-20 container mx-auto'>
                <h1 className='text-4xl font-bold text-center '>Contact <span className='text-primary'>Brosislam</span></h1>
                <h5 className='font-semibold text-center text-base'>Have questions or need more information? Contact us today!</h5>
                <div className='mt-10 grid md:grid-cols-2 sm:grid-cols-1 gap-4'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.04386154389!2d72.8105622753171!3d23.132071979097194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e792139fef419%3A0x3339e77281a11c4a!2sBrosis%20Laminates!5e0!3m2!1sen!2sin!4v1722101939334!5m2!1sen!2sin" className='h-96 w-full' title='Google Maps' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className='flex flex-col justify-center'>
                        <p className='my-2 text-xl'> <span className='text-primary font-bold '>Address :</span> Plot No. 840 (774/1), Village Vasna Rathod, Near Dahegam Powergrid, Dahegam, Ahmedabad-382305, Gujarat, India</p>
                        <p className='my-2 text-xl'> <span className='text-primary font-bold '>Contact :</span> +91-9722229901</p>
                        <p className='my-2 text-xl'> <span className='text-primary font-bold '>Email :</span> contact@brosislam.com</p>
                        <p className='my-2 text-xl'> <span className='text-primary font-bold '>Working Days :</span> Monday To Saturday</p>
                    </div>
                </div>
            </section>
            <footer className="bg-gray-800 text-white text-center py-4">
                <p>© Brosis Pvt. Ltd. 2022 | All Rights Reserved</p>
            </footer>
        </>
    );
};

export default Footer;
